import React from 'react';
import '../sass/components/footer.scss';
import cliveAiLogo from '../assets/images/clive-ai-logo.png';

const Footer = () => {
  return (
    <footer>
      <a href="/#" className="logo">
        <img src={cliveAiLogo} alt="clive.ai logo" />
      </a>
    </footer>
  );
};

export default Footer;
