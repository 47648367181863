import React from 'react';
import '../sass/pages/our-partners.scss';
import ptAiLogo from '../assets/images/pt-ai-logo.png';
import ptAiScreenshots from '../assets/images/pt-ai-screenshots.png';

const OurPartners = () => {
  return (
    <section id="our-partners">
      <div className="main-container">
        <h3>Our Partners</h3>
        <div className="pt-container">
          <img className="logo" src={ptAiLogo} alt="Play Today Ai Logo" />
          <img
            className="screenshots"
            src={ptAiScreenshots}
            alt="Play Today Ai Screenshots"
          />
        </div>
      </div>
    </section>
  );
};

export default OurPartners;
