import React from 'react';
import '../sass/components/navbar.scss';
import cliveAiLogo from '../assets/images/clive-ai-logo.png';

const Navbar = () => {
  return (
    <header>
      <a href="/#" className="logo">
        <img src={cliveAiLogo} alt="clive.ai logo" />
      </a>
      <nav>
        <a href="#about-us">About Us</a>
        <a href="#features">Features</a>
        <a href="#our-partners">Our Partners</a>
        <a href="#contact-us">Contact Us</a>
      </nav>
    </header>
  );
};

export default Navbar;
