import React from 'react';
import '../sass/pages/header.scss';

const Header = () => {
  return (
    <section id="/" className="header-section">
      <h1>Sports Ai API Services</h1>
      <h2>Take Ownership of the Ai Revolution for Your Sports Brand</h2>
      <button>Find out more</button>
      {/* <img /> */}
    </section>
  );
};

export default Header;
