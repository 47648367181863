import React from 'react';
import '../sass/pages/about-us.scss';
import blueCircle from '../assets/images/blue-circle.png';

const AboutUs = () => {
  return (
    <section id="about-us">
      <div className="embrace-group">
        <h3>Embrace the AI Revolution in Sports</h3>
        <p>
          At Clive.Ai, we specialize in providing advanced AI API solutions for
          sports media, product, and service brands. Our mission is to empower
          your brand by leveraging cutting-edge generative AI technology,
          transforming the way sports data, media, and products are experienced.
          With our unique expertise in sports AI, we offer seamless integration,
          enabling you to enhance your offerings, innovate in product
          development, and engage your audience like never before.
        </p>
      </div>
      <div className="leading-text">
        <h3>
          Leading the Way in <br /> Sports AI Technology
        </h3>
        <img src={blueCircle} alt="circle" />
      </div>
      <div className="leading-columns">
        <div>
          <h4 className="blue-underline">AI Pioneers</h4>
          <p>
            Dedicated to serving media and sports brands with innovative AI.
          </p>
        </div>
        <div>
          <h4 className="pink-underline">Business Revolution</h4>
          <p>Introducing powerful, easy-to-integrate AI tools.</p>
        </div>
        <div>
          <h4 className="yellow-underline">Digital Enhancement</h4>
          <p>Bridging sports and technology for brand growth and engagement.</p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
