import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navbar from './components/Navbar';
import Header from './pages/Header';
import AboutUs from './pages/AboutUs';
import Features from './pages/Feratures';
import UseCases from './pages/UseCases';
import OurPartners from './pages/OurPartners';
import ContactUs from './pages/ContactUs';
import Footer from './components/Footer';
// import CookiesConsent from './components/CookiesConsent';

function App() {
  return (
    <>
      {/* SEO Helmet */}
      <Helmet>
        <title>Clive.Ai</title>
        <meta property="og:site_name" content="deski" />
        <meta property="og:url" content="https://clive.ai" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Clive.Ai" />
        <meta
          name="keywords"
          content="clive.ai, clive, ai, golf, sports, artificial intelligence, artificial, intelligence, api"
        />
        <meta name="description" content="Sports AI API Services" />
      </Helmet>
      <Router>
        <Navbar />
        {/* Sections */}
        <Header />
        <AboutUs />
        <Features />
        <UseCases />
        <OurPartners />
        <ContactUs />
        <Footer />
        {/* <Route path={'/features'} component={Features} />
          <Route path={'/our-partners'} component={OurPartners} />
          <Route path={'/contact-us'} component={ContactUs} /> */}
      </Router>
      {/* <CookiesConsent /> */}
    </>
  );
}

export default App;
