import React from 'react';
import '../sass/pages/contact-us.scss';

const ContactUs = () => {
  return (
    <section id="contact-us">
      <h3>Contact Us</h3>
    </section>
  );
};

export default ContactUs;
