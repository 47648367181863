import React from 'react';
import '../sass/pages/features.scss';
import { IconType } from 'react-icons';
import { TbWorld } from 'react-icons/tb';
import { BsBarChart } from 'react-icons/bs';
import { IoPlayOutline, IoBulbOutline } from 'react-icons/io5';
import { GrEdit } from 'react-icons/gr';
import { VscSettingsGear } from 'react-icons/vsc';

interface IFeatureItem {
  title: string;
  description: string;
  icon: IconType;
  blueAccent: boolean;
}

const Features = () => {
  const featureList: IFeatureItem[] = [
    {
      title: 'Global Reach',
      description:
        'Our solutions support over 30 languages, ensuring your sports brand resonates worldwide.',
      icon: TbWorld,
      blueAccent: true,
    },
    {
      title: 'Automated Data Analysis',
      description:
        'Dive into automated extraction and analysis of sports data, ensuring accuracy and insights',
      icon: BsBarChart,
      blueAccent: false,
    },
    {
      title: 'Interactive Experiences',
      description:
        'Re-engage users by transforming databases into dynamic user experiences.',
      icon: IoPlayOutline,
      blueAccent: true,
    },
    {
      title: 'Content Creation',
      description:
        'Generate high-quality, fact-checked sports articles and content effortlessly.',
      icon: GrEdit,
      blueAccent: false,
    },
    {
      title: 'Customizable APIs',
      description:
        'Our tailored APIs are designed for ease of integration, offering comprehensive data handling and efficient partner management. They simplify complex processes, allowing you to focus on innovation and growth.',
      icon: VscSettingsGear,
      blueAccent: true,
    },
    {
      title: 'Product Innovation',
      description:
        'Use Ai insights to drive product development and understand customer preferences, setting new benchmarks in sports product innovation.',
      icon: IoBulbOutline,
      blueAccent: false,
    },
  ];

  const FeatureItem = (props: { item: IFeatureItem; index: number }) => {
    const { item, index } = props;
    return (
      <li key={index}>
        <div className="item-container">
          <div className={`item-icon ${item.blueAccent ? 'blue-accent' : ''}`}>
            <item.icon />
          </div>
          <div className="item-text">
            <h4 className={item.blueAccent ? 'blue-accent' : ''}>
              {item.title}
            </h4>
            <p>{item.description}</p>
          </div>
        </div>
      </li>
    );
  };

  return (
    <section id="features">
      <h3>Features and Benefits</h3>
      <ul className="feature-list">
        {featureList.map((item, index) => (
          <FeatureItem item={item} index={index} />
        ))}
      </ul>
    </section>
  );
};

export default Features;
