import React from 'react';
import '../sass/pages/use-cases.scss';
import golfGptImage from '../assets/images/golf-gpt-image.png';

const UseCases = () => {
  return (
    <section id="use-cases">
      <h3>Use Cases</h3>
      <div className="card">
        <img src={golfGptImage} alt="golf-gpt"/>
        <div>
          <h2>Golf GPT</h2>
          <p>
            Discover Golf GPT on ChatGPT, your Ai-powered golf assistant. It
            simplifies the game by providing quick answers to rules and offering
            personalized tips. This tool demonstrates our multi-platform
            expertise in sports Ai, making golf more enjoyable and accessible
            for everyone.
          </p>
          <button>Try Golf GPT</button>
        </div>
      </div>
    </section>
  );
};

export default UseCases;
